<template>
  <div :style="[accentStyle]" style="background-color: black;padding-bottom: 0px;">
    <!--header-->
    <LeaderBoardHeader
      :accent-colour="accentColour"
      :club-logo-url="club.ClubLogoURL"
    />

    <!--main body-->
    <div style="padding:0px;">
      <div
        class="backGround"
        :style="{ 'background-image': 'url(' + club.SplashImg + ')' }"
      >
        <div style="padding:15px;">
          <div class="midDiv1">
            <div
              class="primary-colour midDiv1_1"
              style="color: var(--override-color) !important;"
            >
              <div class="mobileOnly1" style="display: flex !important;justify-content: center;">
                <img
                  v-if="club.ClubLogoURL"
                  :src="club.ClubLogoURL"
                  :class="accentColour === '#1FFC35' ? 'badgePadding' : ''"
                  style="height:120px;margin-bottom: 15px;"
                  class="mr-2"
                >
              </div>
              <div
                v-if="userData.aut"
                class="mobileWelcome"
                style="margin-top:-30px;color: var(--override-color) !important;"
              >
                Welcome back {{ userData.fnm }}!<br>
              </div>
              Support {{ club.Name }}<br>by playing the grassroots lottery<br>
              <div class="mobileOnly">
                <mdb-btn
                  style="width:140px !important;font-size:1.2rem;padding:10px !important;right:0px;background-color: var(--override-color) !important;"
                  class="btn primary-btn btn-radius btn-sm p-0 m-0 midDiv1PlayBtn"
                  @click="playNow()"
                >
                  <div style="margin-top:-4px;">
                    PLAY NOW
                  </div>
                </mdb-btn>
              </div>
              <div class="desktopOnly">
                <mdb-btn
                  style="width:260px !important;font-size:2.5rem;padding:10px !important;right:0px;background-color: var(--override-color) !important;height: 80px !important;border-radius: 60px !important;margin-top: 10px !important;"
                  class="btn primary-btn p-0 m-0 midDiv1PlayBtn"
                  @click="playNow()"
                >
                  <div style="margin-top:-6px;">
                    PLAY NOW
                  </div>
                </mdb-btn>
              </div>
            </div>
          </div>
          
          <!--countdown-->
          <Countdown
            :accent-colour="accentColour"
          />
        </div>
      </div>

      <!--leaderboard-->
      <div style="margin-top:-10px;">
        <div class="deskTopPadding" style="padding:15px;">
          <div
            class="primary-colour"
            :class="leaderboard.length < 11 ? 'largerDivHeight' : 'evenLargerDivHeight'"
            style="padding:15px;border:solid 3px;border-radius:15px;min-height:400px;"
            :style="{'border-color' : accentColour }"
          >
            <div class="leaderBoardMain">
              MONTHLY LEADERBOARD
              <br>
              <div
                class="primary-colour"
                :style="{'color' : accentColour }"
              >
                <div class="hideMobile">
                  {{ desk_month }} {{ year }} 
                  <i
                    v-if="showNextMonthChevron"
                    class="fa fa-chevron-circle-right rightChevron"
                    style="cursor:pointer;"
                    title="Next month"
                    @click="nextMonth"
                  /> 
                  <i
                    v-else
                    class="fa fa-chevron-circle-right rightChevron"
                    style="opacity: 0.5;"
                    title="Next month"
                  /> 
                  <i
                    v-if="showLastMonthChevron"
                    class="fa fa-chevron-circle-left rightChevron"
                    style="cursor:pointer;"
                    title="Last month"
                    @click="lastMonth"
                  />
                  <i
                    v-else
                    class="fa fa-chevron-circle-left rightChevron"
                    style="opacity: 0.5;"
                    title="Last month"
                  />
                </div>
                <div class="showMobile">
                  <i
                    class="fa fa-chevron-circle-left leftChevron"
                    style="cursor:pointer;"
                    title="Last month"
                    @click="lastMonth"
                  />
                  {{ mob_month }} {{ year }} 
                  <i
                    v-if="showNextMonthChevron"
                    class="fa fa-chevron-circle-right rightChevron"
                    style="cursor:pointer;"
                    title="Next month"
                    @click="nextMonth"
                  /> 
                  <i
                    v-else
                    class="fa fa-chevron-circle-right rightChevron"
                    style="opacity: 0.5;"
                    title="Next month"
                  /> 
                </div>
              </div>
            </div>
            <div class="primary-colour winnersPrizeDiv" :style="{'border-color' : accentColour }">
              <div style="color:white;text-align:center;" class="wpmt">
                <div v-if="prize > 0">
                  <span v-if="leaderboard.length > 0">WINNERS PRIZE</span>
                  <span v-else>FORECAST PRIZE</span>
                  <br>
                </div>
                <div v-else>
                  WINNERS PRIZE
                  <br>
                </div>
                <div
                  v-if="prize > 0"
                  class="primary-colour winnersPrizeFont"
                  style="font-size:2rem;"
                  :style="{'color' : accentColour }"
                >
                  £{{ prize }}
                </div>
                <div
                  v-else
                  class="primary-colour winnersPrizeFont"
                  style="font-size:1.2rem;margin-top: 7px;"
                  :style="{'color' : accentColour }"
                >
                  It's just for fun!
                </div>
              </div>
            </div>
            <div
              v-if="leaderboard.length > 0"
              class="bTable"
            >
              <BTable
                id="leaderboard-table"  
                class="text-left clubsTable mt-3 mt-md-4 mb-5"
                :class="leaderboard.length < 11 ? 'largerTable' : 'evenLargerTable'"
                style="overflow-y: auto;"
                head-variant="dark"
                :items="leaderboard"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fields"
                responsive
                :striped="true"
                hover
                small
                sort-icon-left
                auto-width
              >
                <template v-slot:cell(position)="data">
                  <div
                    v-if="data.item.Pos > 0"
                    class="adminTableRow"
                    style="margin-top:12px;"
                  >
                    {{ data.item.Pos }}<span v-if="data.item.Pos > 0">.</span>
                    <div
                      v-if="data.item.Pos === 1"
                      class="desktopOnly"
                      style="margin-top:-23px;margin-left:20px;"
                      :style="{'color' : accentColour }"
                    >
                      <i class="fa fa-crown" />
                    </div>
                  </div>
                </template>
                <template v-slot:cell(player)="data">
                  <div
                    class="adminTableRow"
                    style="margin-top:12px;color: var(--override-color) !important;"
                  >
                    {{ data.item.PlayerName }}
                  </div>
                </template>
                <template v-slot:cell(nickname)="data">
                  <div
                    class="adminTableRow"
                    style="margin-top:12px;color: var(--override-color) !important;"
                  >
                    {{ data.item.NickName }}
                  </div>
                </template>
                <template v-slot:cell(basketid)="data">
                  <div
                    class="adminTableRow"
                    style="margin-top:12px;cursor:pointer;text-decoration:underline;color: var(--override-color) !important;"
                    @click="getHero(data.item)"
                  >
                    VIEW
                  </div>
                </template>
                <template v-slot:cell(lastscore)="data">
                  <div
                    class="adminTableRow"
                    style="margin-top:12px;color: var(--override-color) !important;"
                  >
                    {{ data.item.LastScore }}
                  </div>
                </template>
                <template v-slot:cell(totalscore)="data">
                  <div
                    v-if="mobileView"
                    class="adminTableRow"
                    style="text-align: center;margin-top:12px;margin-left:-20px !important;color: var(--override-color) !important;"
                  >
                    {{ data.item.TotalScore }}
                  </div>
                  <div
                    v-else
                    class="adminTableRow"
                    style="margin-top:12px;color: var(--override-color) !important;"
                  >
                    {{ data.item.TotalScore }}
                  </div>
                </template>
              </BTable>
              <div
                class="mobileCenter"
                style="margin-top: -20px;"
                :style="{'color' : accentColour }"
              >
                For draws: {{ fordraws }}
              </div>
              <div
                v-if="justForFun === true"
                class="mobileCenter"
                style="text-align: left;margin-top: 10px;color:white;display:none;"
              >
                Only {{ neededToPlay }} more entries before the monthly prize pot commences
              </div>
              <div
                v-if="nextPrizeAmount === true"
                class="mobileCenter"
                style="text-align: left;margin-top: 10px;color:white;display:none;"
              >
                Only {{ neededToPlay }} more entries before the monthly prize pot increases to £{{ prize + 50 }}
              </div>
            </div>
            <div
              v-else
              class="bTable"
              :style="{'color' : accentColour }"
            >
              <div v-if="!showNextMonthChevron">
                <div style="margin-top:40px;font-size: 2rem;text-align: center;">
                  LEADERBOARD COMING SOON!
                </div>
                <div style="margin-top:0px;font-size: 1rem;text-align: center;">
                  WE'RE MOST LIKELY WAITING FOR THE FIRST DRAW OR FIRST ENTRIES TO TAKE PLACE! 
                </div>
              </div>
              <div v-else>
                <div style="margin-top:40px;font-size: 2rem;text-align: center;">
                  THERE WAS NO LEADERBOARD FOR THIS MONTH!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <mdb-modal
      id="card"
      class="black-text"
      :show="showHero"
      @close="showHero = false"
    > 
      <mdb-modal-header
        class="modal-custom-header"
        style="color: var(--override-color);border-left: solid 2px var(--override-color);border-top: solid 2px var(--override-color);border-right: solid 2px var(--override-color);"
      >
        <mdb-modal-title>
          <span style="color: var(--override-color) !important;">{{ playerName }}'s Hero</span>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body
        class="modal-custom-body"
        style="border-left: solid 2px var(--override-color);border-right: solid 2px var(--override-color);"
      >
        <div style="width: 100%;display:flex;justify-content: center;">
          <div class="cardViewOuter" style="display:flex;justify-content: center;width: 250px;">
            <CardPreviewFromProps
              style="border: solid 2 px var(--override-colour) !important;height: 600px;margin-top: 4px;"
              class="mr-3 my-2 cardViewInner altHoudiniBorder"
              :hero="hero"
              :colour="colour"
              :sport="sport"
              :number="number"
              :first-initial="firstInitial"
              :second-initial="secondInitial"
              :houdini-border="true"
            />
          </div>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer
        class="modal-custom-footer"
        style="color: var(--override-color);border-left: solid 2px var(--override-color);border-bottom: solid 2px var(--override-color);border-right: solid 2px var(--override-color);"
      />
    </mdb-modal>

    <mdb-modal
      id="login"
      class="black-text"
      :show="login"
      @close="login = false"
    > 
      <mdb-modal-header
        class="modal-custom-header"
        style="color: var(--override-color);border-left: solid 2px var(--override-color);border-top: solid 2px var(--override-color);border-right: solid 2px var(--override-color);"
      >
        <mdb-modal-title>
          <span style="color: var(--override-color) !important;">Login</span>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body
        class="modal-custom-body"
        style="border-left: solid 2px var(--override-color);border-right: solid 2px var(--override-color);"
      >
        <AuthPanel
          :accent-colour="accentColour"
        />
      </mdb-modal-body>
      <mdb-modal-footer
        class="modal-custom-footer"
        style="color: var(--override-color);border-left: solid 2px var(--override-color);border-bottom: solid 2px var(--override-color);border-right: solid 2px var(--override-color);"
      />
    </mdb-modal>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'
import basketService from '@/api-services/basket.service'
import CardPreviewFromProps from '../cardPreviews/cardPreviewFromProps.vue'
import AuthPanel from '@/components/authPanel/leaderboardAuth.vue'
import Countdown from './countdown.vue'
import LeaderBoardHeader from './leaderboardheader.vue'

export default {
  name: 'Leaderboard',
  components: { CardPreviewFromProps, AuthPanel, Countdown, LeaderBoardHeader },
  data () {
    return {
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      perPage: 50,
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      leaderboard: [],
      desk_month: '',
      mob_month: '',
      year: '',
      m: null,
      club: [],
      noData: null,
      showNextMonthChevron: false,
      showLastMonthChevron: true,
      fordraws: '',
      prize: 0,
      showHero: false,
      playerName: '',
      hero: '',
      colour: '',
      sport: '',
      number: '',
      firstInitial: '',
      secondInitial: '',
      login: false,
      accentColour: '#1FFC35',
      justForFun: false,
      neededToPlay: 0,
      noEntries: 0,
      autoLoadedLastMonth: false,
      nextPrizeAmount: false
    }
  },
  computed: {
    ...mapGetters([
      'userData', 'clubUrl', 'mobileView'
    ]),
    accentStyle() {
			return {
				"--override-color": this.accentColour,
			}
		},
    fields () {
      if (this.windowWidth < 577) {
        return [
          { key: 'Position', label: 'Pos',  },
          { key: 'NickName', label: 'Player'},
          { key: 'BasketId', label: 'Card' },
          { key: 'TotalScore', label: 'Score' }
        ]
      } else {
        return [
          { key: 'Position', label: 'Pos' },
          { key: 'Player', label: 'Player' },
          { key: 'NickName', label: 'Nickname' },
          { key: 'BasketId', label: 'Card' },
          { key: 'LastScore', label: 'Last Score' },
          { key: 'TotalScore', label: 'Total Score' }
        ]
      }
    },
    rows () {
      try {
        return this.leaderboard.length
      } catch { return 0 }
    }
  },
  created() { 
    //TO DO
    //if (this.userData.aut) {
      //re-load leader board highlighting logged in user's position
    //}

    this.getClubFromUrl()
    var d = new Date();
    this.m = d.getMonth();
    
    const month = d.toLocaleString('default', { month: 'long' });
    if (month == "January") { this.m = 1; }
    if (month == "February") { this.m = 2; }
    if (month == "March") { this.m = 3; }
    if (month == "April") { this.m = 4; }
    if (month == "May") { this.m = 5; }
    if (month == "June") { this.m = 6; }
    if (month == "July") { this.m = 7; }
    if (month == "August") { this.m = 8; }
    if (month == "September") { this.m = 9; }
    if (month == "October") { this.m = 10; }
    if (month == "November") { this.m = 11; }
    if (month == "December") { this.m = 12; }

    this.desk_month = d.toLocaleString('default', { month: 'long' });
    this.mob_month = d.toLocaleString('default', { month: 'short' });
    this.year = d.getFullYear();
    this.showNextMonthChevron = false
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async getHero(basket) {
      try {
        const res = await basketService.getHero(basket.BasketId, this.club.ClubId)
        this.playerName = basket.PlayerName
        this.showHero = true
        this.hero = res.data.Hero
        this.sport = res.data.Sport
        this.colour = res.data.Colour
        this.number = res.data.Number
        this.firstInitial = res.data.FirstInitial
        this.secondInitial = res.data.SecondInitial

      } catch {
        //could not get hero
      }

    },
    playNow() {
      this.$router.push('/').catch(() => {})
    },
    getClubFromUrl () {
      this.setDefaultClub(this.clubUrl)
    },
    async setDefaultClub (clubUrl) {
      try {
        const res = await basketService.getClub(clubUrl)
        this.club = res.data
        this.accentColour = this.club.AccentColour
        this.getClubLeaderBoard(this.club.ClubId, this.m, this.year)
      } catch {
        this.$router.push('/').catch(() => {})
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    getClubLeaderBoard (cid, mth, yer) {
      this.noData = false
      baseService.getClubLeaderBoard(cid, mth, yer).then((res) => {
        this.leaderboard = res.data.LeaderboardList
        this.fordraws = res.data.ForDraws
        this.prize = res.data.Prize.toFixed(2)
        this.noEntries = res.data.NoEntries

        if (this.noEntries > 0) {
          if (res.data.LeaderboardList.length === 0 && !this.autoLoadedLastMonth) {
            //this.lastMonth()
            this.autoLoadedLastMonth = true
          }
        }

        if (this.noEntries < 50) {
          this.justForFun = true
          this.nextPrizeAmount = false
        } else {
          this.justForFun = false
          this.nextPrizeAmount = true
        }
        this.neededToPlay = (50 - this.noEntries)
        if (this.leaderboard == null) {
          this.noData = true
        }
        let currentDate = new Date();
        if (mth-1 < currentDate.getMonth() && yer <= currentDate.getFullYear()) {
          this.justForFun = false
          this.nextPrizeAmount = false
        }

      })
    },
    nextMonth() {
      if (!this.club.DemoClub || this.noEntries > 0) {
        this.noData = false
        this.m = this.m + 1
        if (this.m == 13) { this.m = 1; this.year++; }
        let date = new Date()
        var month = date.getMonth() + 1
        if (month <= this.m && this.year >= date.getFullYear()) {
          this.showNextMonthChevron = false
        }
        if (this.m >= 6 && this.year >= 2023) {
          this.showLastMonthChevron = true
        }
        if (this.m == 1) { this.desk_month = "January"; this.mob_month = "Jan"; }
        if (this.m == 2) { this.desk_month = "February"; this.mob_month = "Feb"; }
        if (this.m == 3) { this.desk_month = "March"; this.mob_month = "Mar"; }
        if (this.m == 4) { this.desk_month = "April"; this.mob_month = "Apr"; }
        if (this.m == 5) { this.desk_month = "May"; this.mob_month = "May"; }
        if (this.m == 6) { this.desk_month = "June"; this.mob_month = "Jun"; }
        if (this.m == 7) { this.desk_month = "July"; this.mob_month = "Jul"; }
        if (this.m == 8) { this.desk_month = "August"; this.mob_month = "Aug"; }
        if (this.m == 9) { this.desk_month = "September"; this.mob_month = "Sep"; }
        if (this.m == 10) { this.desk_month = "October"; this.mob_month = "Oct"; }
        if (this.m == 11) { this.desk_month = "November"; this.mob_month = "Nov"; }
        if (this.m == 12) { this.desk_month = "December"; this.mob_month = "Dec"; }
        this.getClubLeaderBoard(this.club.ClubId, this.m, this.year)
      }
    },
    lastMonth() {
      if (!this.club.DemoClub || this.noEntries > 0) {
        this.showNextMonthChevron = true
        this.noData = false
        this.m = this.m - 1;
        if (this.m == 0) { this.m = 12; this.year--; }
        if (this.m <= 6 && this.year <= 2023) {
          this.showLastMonthChevron = false
        }
        if (this.m == 1) { this.desk_month = "January"; this.mob_month = "Jan"; }
        if (this.m == 2) { this.desk_month = "February"; this.mob_month = "Feb"; }
        if (this.m == 3) { this.desk_month = "March"; this.mob_month = "Mar"; }
        if (this.m == 4) { this.desk_month = "April"; this.mob_month = "Apr"; }
        if (this.m == 5) { this.desk_month = "May"; this.mob_month = "May"; }
        if (this.m == 6) { this.desk_month = "June"; this.mob_month = "Jun"; }
        if (this.m == 7) { this.desk_month = "July"; this.mob_month = "Jul"; }
        if (this.m == 8) { this.desk_month = "August"; this.mob_month = "Aug"; }
        if (this.m == 9) { this.desk_month = "September"; this.mob_month = "Sep"; }
        if (this.m == 10) { this.desk_month = "October"; this.mob_month = "Oct"; }
        if (this.m == 11) { this.desk_month = "November"; this.mob_month = "Nov"; }
        if (this.m == 12) { this.desk_month = "December"; this.mob_month = "Dec"; }
        this.getClubLeaderBoard(this.club.ClubId, this.m, this.year)
      }
    }
  }
}
</script>

<style>
.smallerDivHeight {
  height: 350px;
}
.largerDivHeight {
  height: 550px ;
}
.largerTable {
  height: 250px;
}
.evenLargerTable {
  height: 700px;
}
.evenLargerDivHeight {
  height: 990px ;
}
.smallerTable {
   height: 100px !important;
}
.thead-dark {
  position: sticky;
  top: 0;
}
.tableHeight {
  height: calc(100vh - 650px);
}
.badgePadding {
  padding: 10px;
  background: white;
  border-radius: 20px;
}
.cardViewInner {
  height:400px;
  width:200px;
}
.close {
  color: var(--override-colour) !important;
}
.navTopDiv {
  height:120px !important;
}
.topDiv1 {
  width:33%;
  float:left;
  padding:10px;
}
.topDiv2 {
  width:33%;
  float:left;
  padding:5px;
  display:flex !important;
  justify-content:center;
}
.topDiv3 {
  width:33%;
  float:left;
  padding:10px;
}
.midDiv1 {
  display:flex;
  justify-content:center;
  width:60%;
  float:left;
}
.midDiv2 {
  width:30%;
  float:right;
  margin-top:15px;
  color:white;
  margin-right:60px;
}
.midDiv1_1 {
  font-size:2rem;
  margin-bottom:10px;
  text-align:center;
  margin-top:160px;
}
.backGround {
  background-size: cover;
  background-image:url('https://play.grassrootslottery.co.uk/img/generic-leaderboard.png');
  background-color:black;
  height:500px;
  margin-bottom:10px;
  margin-top:-10px;
}
.leaderBoardMain {
  font-size:2rem;
  color:white;
  margin-top:25px;
  margin-left:55px;
  float: left;
}
.leftChevron {
  float:right;
  margin-top: 10px;
}
.rightChevron {
  float:right;
  margin-top:10px;
  margin-right:5px;
}
.showMobile {
  display:none;
}
.hideMobile {
  display:block;
}
.winnersPrizeDiv {
  float:right;
  margin-top:25px;
  margin-right:55px;
  padding:15px;
  border:solid 3px;
  border-radius:15px;
  height:100px;
  width:20%;
}
.bTable {
  margin-top:20px;
  float:left;
  width:calc(100% - 110px);
  margin-left:55px;
}
.clubLogoStyling {
  margin-left:50px;
  float:left;
  width:25%;
  height:250px;
}
.splashContainer {
  float:left;
  width:50%;
  color:white;
  margin-top:40px;
  margin-left: -30px;
}
.leaderBoardPlayNow {
  float:right;
  margin-right:100px;
  margin-top:90px;
}
.desktopOnly {
  display: block;
}
.mobileOnly {
  display:none !important;
}
.mobileOnly1 {
  visibility: hidden;
  margin-top:-135px;
}
.noData {
  font-size: 1rem;
  margin-top:44px;
  color:white;
  background:#343a40;
  height:43px;
  border-bottom:solid 2px #454d55;
  text-align: center;
}
@media (min-width:1181px) {
  .deskTopPadding {
    padding-left:75px !important;
    padding-right:75px !important;
  }
}
@media (max-width:1180px) {
  .topDiv1 {
    width:40%;
    float:left;
    padding:10px;
  }
  .topDiv2 {
    width:20%;
    float:left;
    padding:5px;
    display:flex;
    justify-content:center;
  }
  .topDiv3 {
    width:40%;
    float:left;
    padding:10px;
  }
  .midDiv1 {
    display:flex;
    justify-content:center;
    width:50%;
    float:left;
  }
  .midDiv2 {
    width:45%;
    float:right;
    margin-top:15px;
    color:white;
    margin-right:5px;
  }
  .midDiv1_1 {
    font-size:2rem;
    margin-bottom:10px;
    text-align:center;
    margin-top:110px;
  }
  .desktopOnly {
    margin-top: 0px;
    font-size: 1.7rem !important;
  }
  .splashContainer {
    float:left;
    width:45%;
    color:white;
    margin-top:60px;
    margin-left: 20px;
    font-size: 0.9rem;
  }
  .leaderBoardPlayNow {
    float:right;
    margin-right:50px;
    margin-top:100px;
  }
  .winnersPrizeDiv {
    width:25% !important;
  }
}
@media (max-width:1024px) {
  .evenLargerDivHeight {
    height: 1040px ;
  }
  .topDiv1 {
    width:40%;
    float:left;
    padding:10px;
  }
  .topDiv2 {
    width:20%;
    float:left;
    padding:5px;
    display:flex;
    justify-content:center;
  }
  .topDiv3 {
    width:40%;
    float:left;
    padding:10px;
  }
  .midDiv1 {
    display:flex;
    justify-content:center;
    width:50%;
    float:left;
  }
  .midDiv2 {
    width:45%;
    float:right;
    margin-top:15px;
    color:white;
    margin-right:5px;
  }
  .midDiv1_1 {
    font-size:2rem;
    margin-bottom:10px;
    text-align:center;
    margin-top:110px;
  }
  .desktopOnly {
    margin-top: 0px;
    font-size: 1.7rem !important;
  }
  .splashContainer {
    float:left;
    width:45%;
    color:white;
    margin-top:60px;
    margin-left: 20px;
    font-size: 0.9rem;
  }
  .leaderBoardPlayNow {
    float:right;
    margin-right:50px;
    margin-top:100px;
  }
  .winnersPrizeDiv {
    width:25% !important;
  }
}
@media (max-width:768px) {
  .evenLargerDivHeight {
    height: 1040px ;
  }
  .mobileCenter {
    text-align: center !important;
  }
  .desktopOnly {
    margin-top: 0px;
    font-size: 1.7rem !important;
  }
  .mobGrlLogo {
    height:70px !important;
    margin-top:7px;
  }
  .leaderBoardPlayNow {
    float:none;
    display:block;
    width:100%;
    margin-top:20px;
    display:flex;
    justify-content:center;
    height:auto;
  }
  .splashContainer {
    float:none;
    width:100%;
    color:white;
    font-size:0.95rem !important;
    text-align:center;
    margin-top:10px;
    margin-left:0px;
    height:auto;
  }
  .clubLogoStyling {
    float:none;
    display:block;
    margin-left: 0px;
    width:auto;
  }
  .logoImg {
    height: 80% !important;
    margin-left: auto;
    margin-right: auto !important;
  }
  .leaderBoardMain {
    font-size:1.2rem;
    color:white;
    margin-top:15px;
    text-align:center;
    width:100%;
    margin-left:0px;
  }
  .leftChevron {
    float:none;
    margin-right: 5px;
  }
  .rightChevron {
    float:none;
    margin-left: 5px;
  }
  .showMobile {
    display:block;
  }
  .hideMobile {
    display:none;
  }
  .winnersPrizeDiv {
    float:none;
    margin-top:100px;
    padding:15px;
    border:solid 3px;
    border-radius:15px;
    height:80px;
    width:40% !important;
    font-size: 0.8rem !important;
    margin-left: 30%;
    margin-right: 30%;
  }
  .winnersPrizeFont {
    font-size: 1.2rem !important;
  }
  .divMobileHeight3 {
    height: 485px !important;
  }
  .divMobileHeight4 {
    height: 450px !important;
  }
  .bTable {
    margin-top:20px;
    float:none;
    width:100%;
    margin-left: 5px;
  }
}
@media (max-width:576px) {
  .evenLargerDivHeight {
    height: 1040px ;
  }
  .smallerDivHeight {
    height: 450px !important;
  }
  .starsMobileView {
    height: 75px !important;
  }
  .wpmt {
    margin-top:-3px;
  }
  .tableHeight {
    height: 250px;
  }
  .cardViewOuter {
    width: calc(100dvw - 75px) !important;
  }
  .cardViewInner {
    height: calc(100dvh - 160px) !important;
  }
  .mobileWelcome {
    margin-top: 0px !important;
  }
  .mobileOnly {
    display:block !important;
  }
  .mobileOnly1 {
    visibility:visible;
    margin-top:0px;
  }
  .midDiv1PlayBtn {
    margin-top: 15px !important;
  }
  .backGround {
     height: 750px !important;
  }
  .navTopDiv {
    height:75px !important;
  }
  .loginBtn {
    margin-top:2px !important;
    margin-right: 2px !important;
  }
  .mobGrlLogo {
    height:45px !important;
  }
  .mobClubLogo{
    height:50px !important;
  }
  .topDiv3PlayBtn {
    display:none;
  }
  .midDiv1 {
    width: 100% !important;
  }
  .midDiv2 {
    width: 100% !important;
    margin-right: 0px !important;
  }
  .midDiv1_1 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 5px;
  }
  .noData {
    font-size: 1rem;
    margin-top:45px !important;
    color:white;
    background: none;
    height:43px;
    border-bottom:none;
    text-align: center;
  } 
  .mobileCenter {
    text-align: center !important;
  }
  .desktopOnly {
    display: none !important;
  }
  .leaderBoardPlayNow {
    float:none;
    display:block;
    width:100%;
    margin-top:30px;
    display:flex;
    justify-content:center;
    height:auto;
  }
  .splashContainer {
    float:none;
    width:100%;
    color:white;
    font-size:0.9rem !important;
    text-align:center;
    margin-top:10px;
    margin-left:0px;
    height:auto;
  }
  .clubLogoStyling {
    float:none;
    display:block;
    margin-left: 0px;
    width:auto;
  }
  .logoImg {
    margin-left: auto;
    margin-right: auto !important;
  }
  .leaderBoardMain {
    font-size:1.2rem;
    color:white;
    margin-top:5px;
    text-align:center;
    width:100%;
    margin-left:0px;
  }
  .leftChevron {
    float:none;
    margin-right: 5px;
  }
  .rightChevron {
    float:none;
    margin-left: 5px;
  }
  .showMobile {
    display:block;
  }
  .hideMobile {
    display:none;
  }
  .winnersPrizeDiv {
    float:none;
    margin-top:80px;
    padding:15px;
    border:solid 3px;
    border-radius:15px;
    height:80px;
    width:80% !important;
    font-size: 0.8rem !important;
    margin-left: 10%;
    margin-right: 10%;
  }
  .winnersPrizeFont {
    font-size: 1.2rem !important;
  }
  .largerDivHeight {
    height: 600px !important;
  }
  .bTable {
    margin-top:20px;
    float:none;
    width:100%;
    margin-left: 5px;
  }
}
@media (max-width:320px) {
  .splashContainer {
    font-size: 0.6rem !important;
    margin-top: -10px;
    margin-bottom: -5px;
  }
  .midDiv2 {
    display:none;
  }
  .backGround {
     height: 350px !important;
  }
}
</style>